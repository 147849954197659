import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Sheryar Ahmed </span>
            from <span className="purple"> Pakistan, Lahore.</span>
            <br />
            I am currently employed as a software developer at Juspay.
            <br />
            I have completed my BS(hons) in Computer Science at UE,
            Lahore.
            <br />
            <br />
            My journey into web development commenced in 2018, <span className="purple">ignited by the intriguing world of website penetration testing</span>.
            From humble beginnings with HTML & CSS, I discovered the thrill of sculpting captivating website designs, gradually honing my skills to mastery.
            <br /><br />
            In the present day, I've been privileged to contribute to groundbreaking projects at <span className="purple">Oqvest</span>, where I served as both a <span className="purple">backend engineer</span> and <span className="purple">DevOps engineer</span>.
            My professional voyage extends beyond the confines of <span className="purple">Z2A-Tech</span>, showcasing my adaptability in conquering obstacles and crafting resilient solutions.
            <br /><br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Engineering is about designing solutions with precision and creativity, while development brings those designs to life with practical implementation. Both are essential, but they are not the same.!"{" "}
          </p>
          <footer className="blockquote-footer">Sheryar Ahmed</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
