import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ecommerce from "../../Assets/Projects/ecommerce.png";
import fairyhomes from "../../Assets/Projects/fairyhomes.png";
import LMS from "../../Assets/Projects/LMS.png";
import mcqsai from "../../Assets/Projects/mcqsai.png";
import hyperlystintercom from "../../Assets/Projects/hyperlystintercom.png";
import pixy from "../../Assets/Projects/pixy.png";
import standvirtual from "../../Assets/Projects/standvirtual.png";
import virtiv from "../../Assets/Projects/vertiv.png";
import z2atech from "../../Assets/Projects/z2atech.png";
import toc from "../../Assets/Projects/toc.png";
import buzztalk from "../../Assets/Projects/buzz_talk.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {projects.map((proj) => <Col md={4} className="project-card">
            <ProjectCard
              imgPath={proj?.thumbnail}
              isBlog={false}
              title={proj?.title}
              description={proj?.proj_det}
              ghLink={proj?.repo}
              demoLink={proj?.website}
            />
          </Col>)}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;



export const projects = [
  {
    title: 'Bebo Live',
    role: 'Mern Full Stack Developer & UI Designer.',
    proj_det: "Introducing Streamify, a MERN stack streaming application with Socket.IO integration, boasting 11 distinct roles for varied permissions. As the sole architect, I designed its intricate architecture and schema, marking it as my greatest achievement. Streamify's hierarchical structure ensures seamless content management and access control, revolutionizing the streaming experience. Join us on Streamify for a dynamic and secure streaming platform tailored to your needs.",
    website: 'bebolive.com',
    thumbnail: pixy,
  },
  {
    title: 'BuzzTalk',
    role: 'MERN STACK Developer.',
    proj_det: 'Developed using the MERN stack with Socket.io integration, this chat application enables real-time communication between users. With features including instant messaging, user authentication, and chat room creation, it offers a seamless experience for engaging in conversations. Overcoming challenges such as ensuring smooth real-time communication and implementing robust notification handling, this project showcases my ability to create responsive and interactive web applications using modern technologies.',
    website: 'https://polite-grace-blackoops.koyeb.app/signin',
    technologyStack: 'React, MongoDB, Node.js, Express.js, Socket.io, Redux.js, Tailwind css',
    challengesAndSolutions: 'Faced challenges in creating responsive designs for Desktop, Tablet, and Mobile. Overcame challenges through Bootstrap features and Figma design guidelines.',
    results: 'Vertiv is a shopping checkout page with a responsive design that provides a seamless experience across different devices.',
    reflection: 'The project provided valuable insights into frontend development with HTML5, CSS3, and Bootstrap for creating responsive designs.',
    thumbnail: buzztalk,

  },
  {
    title: 'TrustOnCloud',
    role: 'Mern Full Stack Developer & UI Designer.',
    proj_det: 'TrustOnCloud provides complete control catalogs for individual Cloud Services: 1) risk-based from our detailed threat models, 2) always-updated from new releases, and 3) audit-ready with major compliance frameworks.',
    website: 'https://controlcatalog.trustoncloud.com/dashboard/aws/s3',
    thumbnail: toc,
  },
  {
    title: 'Chic Choice Maven (Ecommerce Website)',
    role: 'Full Mern Stack Developer/ & UI Designer.',
    proj_det: 'An ecommerce store built with MERN stack, and utilizes third-party APIs. This ecommerce store enables three main different flows or implementations: Buyers browse the store categories, products, and brands. Sellers or Merchants manage their own brand component.',
    repo: 'https://github.com/Sheryar-Ahmed/E-Commerce-MernStack-project',
    website: 'https://wicked-cod-frock.cyclic.app/',
    technologyStack: 'MERN (MongoDB, Express.js, React, Node.js)',
    apiIntegration: 'Leveraged third-party APIs to enhance the overall functionality and user experience.',
    keyContributions: 'Executed end-to-end development using the MERN stack, ensuring a scalable and efficient ecommerce solution. Designed an intuitive and visually appealing user interface to elevate the overall user experience.',
    challengesAndSolutions: 'Overcame challenges in integrating third-party APIs seamlessly, ensuring a smooth user experience. Implemented robust security measures to safeguard user data and transactions.',
    results: 'Chic Choice Maven stands as a testament to a successful ecommerce platform, offering a user-friendly interface for buyers and empowering sellers with efficient brand management tools.',
    reflection: 'The project provided valuable insights into MERN stack development, UI/UX design, and the intricacies of ecommerce functionalities.',
    thumbnail: ecommerce,

  },
  {
    title: 'Hyperlyst-Intercom',
    role: 'Mern Stack Developer/ & UI Designer.',
    proj_det: 'Hyperlyst is a SAAS-based application. I built its Intercom template from scratch with double search functionality. I designed it for full responsiveness.',
    website: 'https://sites.hyperlyst.com/public/Sin9mS8C1JRt5LI1JwYDG1kI77M2/project/intercom',
    technologyStack: 'MERN (MongoDB, Express.js, React, Node.js)',
    challengesAndSolutions: 'Faced challenges in implementing double search functionality and ensuring full responsiveness. Overcame obstacles through iterative design and testing.',
    results: 'Hyperlyst-Intercom stands as a successful SAAS application template with a seamless search experience and responsive design.',
    reflection: 'The project provided valuable insights into SAAS application development, user interface design, and responsive web design.',
    thumbnail: hyperlystintercom,
  },
  {
    title: 'Z2A Tech',
    role: 'Mern Stack Developer & UI Designer.',
    proj_det: 'This is a software company portfolio created with Gatsby.js for frontend and GraphQL for backend. It\'s a dynamic website with animations and a blog page using GraphQL, deployed on Netlify.',
    website: 'https://www.z2atech.com/',
    technologyStack: 'Gatsby.js (Frontend), GraphQL (Backend)',
    challengesAndSolutions: 'Faced challenges in dynamic website creation and GraphQL integration. Overcame challenges through Gatsby.js features and GraphQL documentation.',
    results: 'Z2A Tech\'s portfolio is a dynamic and animated website that showcases the company\'s services and projects effectively.',
    reflection: 'The project provided valuable insights into Gatsby.js for frontend development and GraphQL for dynamic content management.',
    thumbnail: z2atech,
  },
  {
    title: 'FairyHomes',
    role: 'Mern Stack Developer/ AWS Services & UI Designer.',
    proj_det: 'This is a real estate website for Dubai. It uses AWS services and React.js as a framework. My main focus was on the UI but along with services. I faced challenges during this project, such as data structuring. We used S3 to store images and other information in Dynamo DB.',
    website: 'https://www.fairyhomes.ae/',
    technologyStack: 'React.js (Frontend), AWS Services (S3, Dynamo DB)',
    challengesAndSolutions: 'Faced challenges in data structuring and AWS services integration. Overcame challenges through React.js components and AWS documentation.',
    results: 'FairyHomes is a real estate website that effectively utilizes AWS services for storage and presents a user-friendly UI.',
    reflection: 'The project provided valuable insights into React.js for frontend development and AWS services for cloud-based solutions.',
    thumbnail: fairyhomes,
  }, {
    title: 'MCQSAi',
    role: 'Frontend Developer.',
    proj_det: "Introducing MCQsAI: a cutting-edge React.js application designed for seamless quiz-taking. As the frontend engineer, I've infused it with vibrant animations and ensured full responsiveness. MCQsAI offers a user-friendly interface for engaging with quizzes on any device. Experience real-time feedback and a sleek design, making learning a delightful journey. Join us in redefining the quiz-taking experience with MCQsAI.",
    website: 'https://mcqsai.netlify.app/',
    technologyStack: 'React.js, Tailwind CSS',
    challengesAndSolutions: 'Faced challenges in creating responsive designs for Desktop, Tablet, and Mobile. Overcame challenges through Bootstrap features and Figma design guidelines.',
    results: 'Vertiv is a shopping checkout page with a responsive design that provides a seamless experience across different devices.',
    reflection: 'The project provided valuable insights into frontend development with HTML5, CSS3, and Bootstrap for creating responsive designs.',
    thumbnail: mcqsai,
  },
  {
    title: 'EduHub',
    role: 'Frontend Developer.',
    proj_det: "Introducing EduHub, a MERN stack Learning Management System akin to Udemy. With Stripe integration for payments and Redis for caching, EduHub offers a seamless learning experience. Tailwind CSS and TypeScript ensure sleek design and code efficiency, while an intuitive admin dashboard facilitates easy management. Join EduHub for accessible, efficient online education.",
    website: 'https://e-learning-client-nine.vercel.app/',
    technologyStack: 'React.js, Tailwind CSS',
    challengesAndSolutions: 'Faced challenges in creating responsive designs for Desktop, Tablet, and Mobile. Overcame challenges through Bootstrap features and Figma design guidelines.',
    results: 'Vertiv is a shopping checkout page with a responsive design that provides a seamless experience across different devices.',
    reflection: 'The project provided valuable insights into frontend development with HTML5, CSS3, and Bootstrap for creating responsive designs.',
    thumbnail: LMS,
  },
  {
    title: 'StandVirtual',
    role: 'UI/UX Designer/Developer.',
    proj_det: 'This is a shopping website. I built its landing page with full responsiveness. My main focus was on the UI but along with services. I faced challenges, such as mobile responsive design issues. We used S3 to store images and other information in Dynamo DB.',
    website: 'https://www.standvirtual.com/?category=carros',
    technologyStack: 'UI/UX Design, Responsive Web Design, S3 (Storage), Dynamo DB',
    challengesAndSolutions: 'Faced challenges in mobile responsive design. Overcame challenges through CSS media queries and Dynamo DB storage.',
    results: 'StandVirtual is a visually appealing shopping website with a focus on UI/UX design and responsive web design.',
    reflection: 'The project provided valuable insights into UI/UX design principles and responsive web design techniques.',
    thumbnail: standvirtual,
  },
  {
    title: 'Vertiv',
    role: 'Frontend Developer.',
    proj_det: 'This is a shopping checkout page for Asia with three following designs: Desktop, Tablet, and Mobile. I used HTML5, CSS3, and Bootstrap to build this checkout page as a frontend developer with Figma design.',
    website: 'https://www.vertiv.com/en-asia/',
    technologyStack: 'HTML5, CSS3, Bootstrap',
    challengesAndSolutions: 'Faced challenges in creating responsive designs for Desktop, Tablet, and Mobile. Overcame challenges through Bootstrap features and Figma design guidelines.',
    results: 'Vertiv is a shopping checkout page with a responsive design that provides a seamless experience across different devices.',
    reflection: 'The project provided valuable insights into frontend development with HTML5, CSS3, and Bootstrap for creating responsive designs.',
    thumbnail: virtiv,

  },
];